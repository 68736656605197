<template>
  <ul class="d-flex justify-content-center align-items-center text-muted mb-0">
    <li v-if="current > 1">
      <router-link
        class="d-inline-flex align-items-center"
        :to="{ query: { ...$route.query, page: current - 1 } }"
      >
        <ChevronLeftIcon />
      </router-link>
    </li>

    <li
      v-for="number of numbers"
      :class="{ active: number === current, disabled: number === '...' }"
      :key="number"
    >
      <span v-if="number === '...'">&hellip;</span>
      <span class="current" v-else-if="number === current">{{ number }}</span>
      <router-link v-else :to="{ query: { ...$route.query, page: number } }">
        {{ number }}
      </router-link>
    </li>

    <li v-if="current < last">
      <router-link
        class="d-inline-flex align-items-center"
        :to="{ query: { ...$route.query, page: current + 1 } }"
      >
        <ChevronRightIcon />
      </router-link>
    </li>
  </ul>
</template>

<script>
import ChevronLeftIcon from 'bootstrap-icons/icons/chevron-left.svg';
import ChevronRightIcon from 'bootstrap-icons/icons/chevron-right.svg';

export default {
  components: { ChevronLeftIcon, ChevronRightIcon },

  props: ['current', 'last'],

  methods: {
    /**
     * https://gist.github.com/kottenator/9d936eb3e4e3c3e02598
     *
     * @param {Number} c Current page.
     * @param {Number} l Last page.
     */
    generateNumbers(c, m) {
      var current = c,
        last = m,
        delta = 2,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

      for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || (i >= left && i < right)) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push('...');
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
  },

  computed: {
    numbers() {
      return this.generateNumbers(this.current, this.last);
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  line-height: 1;
}

li {
  > a,
  > span {
    padding: rem(8) rem(12);
    border-radius: $border-radius;
  }

  > a {
    text-decoration: none;
    color: inherit;

    &.d-inline-flex {
      padding-left: rem(8);
      padding-right: rem(8);
    }

    &:hover,
    &:focus {
      background: tint-color($primary, 90%);
    }
  }

  .current {
    color: $white;
    background: $primary;
  }
}
</style>
