<template>
  <div class="wrapper d-flex align-items-center">
    <VDropdown
      class="d-inline-flex align-items-center me-16"
      @apply-show="focusToField"
    >
      <!-- This will be the popover reference (for the events and position) -->
      <button
        class="btn-plain d-inline-flex align-items-center"
        v-tooltip="'Cari'"
      >
        <SearchIcon />
      </button>

      <!-- This will be the content of the popover -->
      <template #popper>
        <div class="popper-content p-16">
          <InputField label="Cari" v-model="input.search" />
        </div>
      </template>
    </VDropdown>

    <ActiveFilters :activeFilters="activeFilters" @remove="removeFilter" />
  </div>
</template>

<script>
import InputField from '../../form/InputField.vue';
import ActiveFilters from '../../table/ActiveFilters.vue';
import BaseFilter from '../../table/BaseFilter.vue';
import VDropdown from '../../v-tooltip/VDropdown.vue';

import SearchIcon from 'bootstrap-icons/icons/search.svg';

export default {
  extends: BaseFilter,

  components: {
    InputField,
    ActiveFilters,
    VDropdown,
    SearchIcon,
  },
};
</script>
