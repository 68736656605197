<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <Table
      rowKey="id"
      :isLoading="$store.getters['grades/isFetching'](params)"
      :columns="columns"
      :rows="$store.getters['grades/get'](params)"
    >
      <template #top>
        <div class="p-16 border-bottom d-flex">
          <GradeFilter />
          <router-link
            class="ms-auto"
            to="/admin/kelas/tambah"
            v-tooltip="'Tambah'"
            ><PlusCircleIcon
          /></router-link>
        </div>
      </template>

      <template #bottom>
        <div class="border-top p-16" v-if="meta && meta.last_page > 1">
          <Pagination :current="meta.current_page" :last="meta.last_page" />
        </div>
      </template>
    </Table>
  </main>
</template>

<script>
import merge from 'lodash/merge';
import gradeRepository from '../../../api/repositories/gradeRepository';

import GradeFilter from '../../../components/admin/grade/GradeFilter.vue';
import Table from '../../../components/table/Table.vue';
import Pagination from '../../../components/table/Pagination.vue';
import RowActions from '../../../components/table/RowActions.vue';

import CalendarWeekIcon from 'bootstrap-icons/icons/calendar-week.svg';
import PencilIcon from 'bootstrap-icons/icons/pencil.svg';
import PlusCircleIcon from 'bootstrap-icons/icons/plus-circle.svg';
import TrashIcon from 'bootstrap-icons/icons/trash.svg';

export default {
  components: { GradeFilter, Table, Pagination, PlusCircleIcon },

  metaInfo: { title: 'Kelas' },

  computed: {
    params() {
      return merge({ sort: { name: 'asc' } }, this.$route.query);
    },

    columns() {
      return [
        { key: 'name', text: 'Kelas' },
        {
          key: 'actions',
          component: RowActions,
          componentProps: {
            actions: () => [
              {
                icon: PencilIcon,
                text: 'Ubah',
                onClick: ({ row }) => this.$router.push(row.editRoutePath),
              },
              {
                icon: CalendarWeekIcon,
                text: 'Jadwal',
                onClick: ({ row }) => this.$router.push(row.scheduleRoutePath),
              },
              {
                icon: TrashIcon,
                text: 'Hapus',
                attrs: { class: 'text-danger' },
                onClick: this.handleRemoveClick,
              },
            ],
          },
          props: { class: 'text-end' },
        },
      ];
    },

    meta() {
      return this.$store.getters['grades/getMeta'](this.params);
    },
  },

  methods: {
    handleRemoveClick({ row }) {
      this.$confirmation.confirm(
        `Anda yakin mau menghapus <b>${row.name}</b>?`,
        {
          onOK: async () => {
            try {
              await gradeRepository.destroy(row.id);
              this.$store.dispatch('grades/fetchAndInvalidate', this.params);
              this.$alert.success('Kelas berhasil dihapus');
            } catch (error) {
              this.$alert.requestError(error);
            }
          },
        }
      );
    },

    fetch() {
      this.$store.dispatch('grades/maybeFetch', this.params);
    },
  },

  beforeMount() {
    this.fetch();
  },

  watch: {
    '$route.query': {
      handler() {
        this.fetch();
      },
      deep: true,
    },
  },
};
</script>
